<template>
  <main class="view">
    <div class="col col-12 schedule-wrapper">
      <ul class="tab-controller">
        <li class="tab-controller__item" :class="[scheduleView == 0 ? 'active' : 'non', true ? '--parent' : '']">
          <router-link class="item-link" style="cursor: pointer;" :to="{ name: 'schedule'}">{{ $t('Holiday') }}</router-link>
          <ul class="item-submenu">
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'schedule-overview'}">{{ $t('Overview') }}</router-link>
            </li>
            <li class="item-submenu__item"  v-if="Check(AdminRoles)">
              <router-link class="submenu-link" :to="{ name: 'schedule-approve'}">{{ $t('Approval') }}</router-link>
            </li>
          </ul>
        </li>
        <!--
        <li :class="scheduleView == 1 ? 'active' : 'non'">
          <router-link class="item-link" :to="{ name: 'timesheet'}"> Timesheet </router-link>
        </li>
        -->
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Schedule',
    mixins: [Permissions, BPA],
    data() {
      return {
        scheduleView: 0,
        RouteMapper: {'schedule': 0, 'timesheet': 1},
        AdminRoles: [/*0, 15*/ 'admin', 'schedule_admin'],
        route: null
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    watch: {
      $route () {
        this.IsInRouteMapper();
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        let name = this.route.split('-')[0];
        if (this.RouteMapper[name] !== null) {
          this.scheduleView = this.RouteMapper[name];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      }
    }
  }
</script>
